.page-community-members .search-bar {
    display: flex;
    justify-content: center;
}

input.search-community-members {
    width: 50%;
    border-radius: 5px 0 0 5px;
    border: 0px;
    padding: 15px;
    font-size: 20px;
}

input.search-community-members:focus-visible {
    outline: none;
}

.clear-btn {
    background: var(--color-primary);
    color: black;
    border: none;
    border-radius: 0 5px 5px 0;
    padding: 0 25px;
    font-weight: 500;
    font-size: 18px;
}

.members-header {
    background: white;
    margin-bottom: 50px;
    padding: 20px;
    border-radius: 5px;
}

.members-count svg {
    border: 1px solid #eaeaea;
    border-radius: 50%;
    padding: 5px;
    box-sizing: content-box;
    font-size: 15px;
}

.members-count {
    display: inline-block;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
}

span.count-number {
    font-size: 11px;
    padding: 2px 8px;
    background-color: #eee;
}

@media (max-width:768px) {
    input.search-community-members {
        width: 100%;
    }
}

.sliderContent.members-list {
    min-height: 316px;
}

.sliderContent.members-list .member-data {
    flex: 1;
}

.member-data .job-location.location{
    justify-content: center;
    margin-top: 12px;
    flex-wrap: wrap;
}


.member-data .job-location.location *{
    margin-top: 0px;
}

.MuiDialog-root.message-modal {}

.MuiDialog-root.message-modal .MuiDialogTitle-root {
    margin: 10px;
    border-radius: 10px;
    background-color: #d3a11f !important;
}

.MuiDialog-root.message-modal .MuiDialogContent-root {
    padding-top: 10px !important;
}

.MuiDialog-root.message-modal .MuiDialogContent-root p {
    font-size: 18px;
    font-weight: 500;
}

.friendship-request-status {
    background-color: #d3a11f;
    padding: 0px 10px;
    font-size: 10pt;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 6px;
    user-select: none;
    -moz-user-select: none;
    min-height: 40px;
}

@media (max-width: 1024px){
    .page-community-members .search-box .search-bar{
        width: calc(100% - 160px);
    }
    .page-community-members .search-box .search-btn{
        width: 140px;
    }
}