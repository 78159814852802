.profile-edit-form {
    input:not([type=radio],[type=submit]),textarea {
        padding: 14px;
        color: #000000;
        background-color: #F6F6F6;
        border: 1px solid #F0F5F7;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        font-size: 20px;
        resize: none;
    }


    input[type=text]:focus {
        border: 1px solid black;
        background: #fff;
        outline: none;
    }


    label {
        font-size: 20px;
    }

    span.required {
        color: red;
    }

    .editorWrapper {
        border: 1px solid #d8d8d8;
    }

    .editorBody {
        padding: 0 10px;
        min-height: 250px;
    }

    .editorToolbar {
        background: #f6f7f7;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }

    .submit-btn button {
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    .upload-box{
        button{
            letter-spacing: 3px;
            padding: 15px 30px;
            word-wrap: normal;
            .filename {
                word-wrap: break-word;
            }
            
            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .upload-box{
        .img{
            position: relative;
            width: 100%;
            padding-top: 100%;

            img{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width:100%;
                border-radius: 100%;
            }
        }
    }
}