.add-note-modal {

    width: 600px;
    background: white;
    padding: 40px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

    .addnote-header {
        display: flex;
        align-items: center;
        gap: 10px;
    }


    .addnote-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        textarea,
        input {
            color: #000000;
            background-color: #F6F6F6;
            padding: 15px 15px 15px 15px;
            resize: none;
            height: auto;
            font-size: 20px;

            &::placeholder {
                color: #999999;

            }
        }

        label {
            font-size: 20px;
        }

        .notes-list-item {
            margin: 0px 0;
        }

        .note-item {
            padding: 15px;
            margin: 15px 0;
            border-radius: 15px;
            border-bottom: 3px solid #f6f6f6;
        }
    }
}

@media (max-width:600px) {
    .add-note-modal {
        max-width: 600px;
        width: 100%;
        padding: 20px;
    }
}

.add-note-modal .close-modal {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.add-note-modal .close-modal:hover {
    color: #d3a11f;
}

.note-item {
    position: relative;
}

.notes-action {
    position: absolute;
    top: 20px;
    right: 0px;
}

.reset-note {
    cursor: pointer;
    margin: 10px!important;
    display: block;
    text-align: center;
}

.note-alert {
    position: relative;
}

.note-alert-close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: black;
    cursor: pointer;
}