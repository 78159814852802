.password-reset{   
    min-height: calc(100vh - 369px);
    display: flex;
    align-items: center;
    width: 100%;

    .password-reset-form {
        width:100%;

        .form-label{
           font-size:20px; 
        }
        .form-control {
            padding: 15px;

            &:focus {
                border: 1px solid var(--color-primary);
            }
            input{
                font-size:20px;
                &::placeholder {
                    color: #999999;
                }
            }
            
        }
    }
} 


@media (max-width: 1024px) and (min-width: 900px){
    .password-reset{
        min-height: 450px;
    }   
}
@media (max-width:430px){
    .password-reset{
        min-height: calc(100vh - 376px);
    }
}

