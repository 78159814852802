.creative-page-agency-shortlist {
    .employer-list {
        position: relative;
        border: 1px solid #ECEDF2;
        margin-bottom: 30px;
        padding: 50px 30px;
    }

    .username {
        font-size: 35px;
    }

    .location>* {
        margin: 0;
    }

    .position {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 3px;

        svg {
            margin-right: 5px;
        }
    }

    .user-meta {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;

        a {
            font-size: 14px;
        }
    }

    .open-jobs-btn {
        text-align: center;
        height: 40px;
        width: 150px;
    }

    .shortlist {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 30px;
        width: 30px;
        padding: 0;
    }

}