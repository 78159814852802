.page-groups {

    .search-bar {
        display: flex;
        justify-content: center;
        // margin-bottom: 20px;
    }

    input.search-community-members {
        border-radius: 5px;
    }

    .group-info {
        font-size: 13px;
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 10px;
        font-weight: 400;
        color: #898989;
    }

    .join-group {
        margin-top: 30px;
        width: 100%;
        display: flex;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
    }

    .group-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .group-btn.leave-group {
        background: transparent;
        border-color: #ebe4e4;
        color: #898989;
    }
}

.group-membership-status {
    background-color: #d3a11f;
    padding: 0px 10px;
    font-size: 10pt;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 6px;
    -webkit-user-select: none;
    user-select: none;
    -moz-user-select: none;
    min-height: 40px;
}

.page-groups a {
    color: black;
}

.page-groups a:hover {
    color: #d3a11f;
}