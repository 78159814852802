body.no-overflow {
  overflow: hidden;
}

.dark-container {
  padding: 50px 0;
  background: black;
  /* margin-bottom: 10px; */
  min-height: calc(100vh - 273px);
}

.scroll-button {
  z-index: 999 !important;
}

.adagencies-slider .shortlist-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_result {
  /* min-height: 400px; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
}

.no_result p {
  font-size: 20px;
  color: #000;
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .no_result {
    margin: 50px;
  }
}

@media (max-width: 1024px) and (min-width: 900px) {
  .dark-container {
    padding-top: 95px;
  }
}

.we-will-be-right-back {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  padding: 50px;
}

.we-will-be-right-back img {
  width: 500px;
  height: auto;
  object-fit: cover;
}

.we-will-be-right-back .buttons {
  display: flex;
  gap: 10px;
}

.we-will-be-right-back .message {
  width: 500px;
}

.we-will-be-right-back .message * {
  margin: 0;
  padding: 0;
}

.we-will-be-right-back .site-logo {
  line-height: 1;
}

@media (orientation:portrait) and (max-width: 767px) {

  .we-will-be-right-back {
    padding: 15px !important;
  }

  .we-will-be-right-back img {
    width: 100% !important;
  }

  .we-will-be-right-back .message {
    width: 100% !important;
  }
}

@media (orientation:landscape) and (max-height: 480px) {

  .we-will-be-right-back {
    padding: 15px !important;
  }

  .we-will-be-right-back img {
    width: auto !important;
    height: 50% !important;
  }

  .we-will-be-right-back .message {
    width: auto !important;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}