.footer {
    padding: 20px 0px 20px 0px;
    background: black;
    color: white;
    text-align: center;
}

.footer .social {
    background: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

.copyrights a:hover {
    color: var(--color-primary);
}

.footer a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
}

.footer .social:hover {
    background: var(--color-primary);
}

.copyrights {
    font-weight: 300;
    font-size: 14px;
}

.footer-title {
    font-weight: 400;
}