.main {
    /* margin-bottom: 50px; */
}

.d-none {
    display: none;
}

h1.bannerHeading {
    font-size: 61px;
    margin: 0;
    color: white;
    font-weight: 400;
    min-height: 73.2px;
}

.banner {
    text-align: center;
    background: black;
    padding: 40px 0 0;
    /* min-height: 95vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.main .banner .film-festival-1 {
    position: absolute;
    top: 30px;
    right: 10px;
}

@media (max-width: 540px) {
    .main .banner .film-festival-1 {
        top: 20px;
    }
}

p.subHeading {
    font-size: 28px;
    margin: 0px;
    color: white;
    margin-bottom: 50px;
    line-height: 32px;
}

.searchArea {
    padding: 20px;
    max-width: 750px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
}

p.searchHeader {
    color: rgb(211, 161, 31);
    font-size: 26px;
    margin: 0;
}

.popularSearch p {
    font-size: 20px;
    margin: 0px;
    font-weight: 300;
    color: white;
}

.popularSearch a {
    color: white;
    text-decoration: none;
}

.popularSearch {
    margin-top: 5px;
}

input.searchInput {
    padding-left: 45px;
    height: 60px;
    box-sizing: border-box;
    font-family: 'JOST';
    border: none;
    font-weight: 300;
    font-size: 20px;
    color: black;
    width: 100%;
}

input.searchInput:focus {
    box-shadow: none
}

.searchBox {
    border: 1px solid;
    border-radius: 8px;
    text-align: left;
    padding: 5px;
    background: white;
}

input.searchInput::placeholder {
    color: black;
}

button.searchBtn {
    background: rgb(119, 119, 119);
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    border-radius: 8px;
    letter-spacing: 3px;
    border: none;
    width: 100%;
    height: 100%;
}

button.searchBtn:hover {
    background: rgb(211, 161, 31);
    color: black;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

button.searchBtn {
    background: rgb(119, 119, 119);
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    border-radius: 8px;
    letter-spacing: 3px;
    border: none;
    width: 100%;
    height: 100%;
}

button.searchBtn:hover {
    background: rgb(211, 161, 31);
    color: black;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.searchIcon {
    position: absolute;
    top: 16px;
    left: 20px;
}

.featureBox * {
    display: block;
}

.featureImg {
    margin: auto;
}

.featureTitle {
    background: var(--color-primary);
    color: black;
    font-size: 23px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    padding: 7px;
    width: 200px;
    margin: 10px auto;
    transition: all 0.3s ease;
}

.featureTitle:hover {
    background: rgb(119, 119, 119);
    color: white;
}

.featureDesc {
    color: white;
    font-size: 20px;
    font-weight: 300;
}

.about-content h5 {
    font-size: 26px;
    font-weight: 400;
    margin-top: 7px;
    margin-bottom: 0;
}

.section.about h1 {
    font-weight: 400;
}

.section {
    padding-top: 36px;
}

.featureBox:hover .featureImg {
    -webkit-animation: updown .8s ease-in-out 0s infinite;
    animation: updown .8s ease-in-out 0s infinite;
}

.location-name {
    font-size: 18px;
    color: rgb(105, 105, 105);
}

.hero-boxes {
    .title {
        text-align: center;
        font-size: 36pt;
        font-weight: 400;
        text-transform: uppercase;
    }

    .subtitle {
        text-align: center;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 20px;
    }

    .box {
        text-align: center;
        position: relative;
        height: 280px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        transform-style: preserve-3d;
        transition: all 1.3s ease;
        box-shadow: 3px 3px 3px #afafaf;
    }

    .hero-box:hover {
        transition: all 1.3s ease;
        transform-style: preserve-3d;

        .flip {
            transform: rotateY(180deg);
        }
    }

    .main-title {
        font-size: 3rem;
        font-family: 'Alta';
        line-height: 45px;
        font-weight: 600;
        width: 100%;
        text-shadow: 1px 0px white;
        padding: 0px 10px;
    }

    .box-link-front,
    .box-link-back {
        padding: 5px;
    }

    .box-link-back {
        position: absolute;
        right: 10px;
        bottom: 17px;
    }

    .box-link-front .link,
    .box-link-back .link {
        color: white;
        /* border: 1px solid white; */
        border-radius: 10px;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
    }

    .title-small {
        /* margin-top: 10px;
        position: absolute;
        width: calc(100% - 60px);
        left: 10px;
        bottom: 12px; */
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        text-transform: uppercase;
        font-size: 12px;
    }

    .flip-card-front,
    .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        line-height: 24px;

        h2 {
            font-size: 18pt;
            padding: 10px 0;
            margin-bottom: 15px;
            border-top: 1px solid;
            border-bottom: 1px solid;
            font-weight: bold;
        }
    }

    .flip-card-front {
        border: 1px solid white;

        .box-link-front,
        .box-link-back {
            border: 1px solid #fff;
            border-radius: 50%;
        }
    }

    .flip-card-back {
        background-color: #fff;
        color: black;
        padding: 15px;
        display: flex;
        align-items: center;

        .box-link-front,
        .box-link-back {
            font-size: 12px;
            display: flex;
            gap: 10px;
            align-items: center;

            p {
                margin-bottom: 0;
            }

            .link {
                border: 1px solid #000;
                border-radius: 50%;
            }
        }

        .content {
            max-width: 250px;
            margin: 0 auto;

            ul {
                text-align: left;
                font-size: 12pt;
                padding-left: 15px;
            }
        }
    }

    .page-single-mentor .box:hover {
        transform: rotateY(180deg);
    }

    .flip-card-back img {
        width: 100%;
        height: 100%;
    }

    .flip-card-back {
        transform: rotateY(180deg);
    }

    .flip-card-front {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }

    @media (max-width: 991px) and (min-width: 767px) {
        .main-title {
            font-size: 1.8rem;
            line-height: 36px;
        }

        .flip-card-back {
            .content {
                ul {
                    font-size: 10pt;
                    line-height: 16px;
                }
            }
        }
    }
}

@keyframes updown {

    50% {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -o-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    0%,
    100% {
        -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@media (max-width:600px) {
    h1.bannerHeading {
        font-size: 48px;
        margin: 20px 0;
    }
}

/* Creative Section Styles */

.creative-section {
    background: black;
    /* margin-bottom: 50px; */
    padding-top: 20px;
}

.creative-section .sectionHeader {
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    align-items: center;
    border: 3px solid white;
    flex-wrap: wrap;
}

.creative-section .sectionTitle {
    font-size: 36px;
    font-weight: 400;
}

.browseAll {
    font-size: 20px;
    letter-spacing: 3px;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
    color: white;
}

.browseAll:hover {
    color: rgb(211, 161, 31) !important
}

.browseAll>a {
    text-decoration: none;
    color: white;
}

.sectionContent {
    margin-top: 20px;
    padding: 0 50px 20px;
}


img.candidateLogo {
    width: 100px;
    height: 100px;
    margin: 0 auto 15px;
    border-radius: 50px;
    object-fit: cover;
}

swiper-slide {
    text-align: center;
}

img.sliderImage {
    width: 200px;
    background: black;
    margin: auto;
}

swiper-slide {
    text-align: center;
}


.sliderContent {
    position: relative;
    text-align: center;
    background: white;
    padding: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    animation: fade-in .3s linear;
    height: 100%;
}

.agencies-slider {
    min-height: 380px;

}

.agencyName {
    font-size: 18px;
}

.position {
    font-size: 16px;
}

.location {
    display: flex;
    align-items: center;
    /* flex: 1; */
}

.location svg {
    margin-right: 5px;
}

.location>* {
    font-size: 20px;
    color: rgb(105, 105, 105);
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    text-decoration: none;
}

.sidebar-item .location span {
    margin: 0;
}

.employer-location .location {
    display: inline-block;
}

.employer-location .location>* {
    display: inline-block;
}

.job-location>* {
    color: inherit !important;
}

.no_location svg,
.no_location a {
    opacity: 0;
}

.profileLink {
    background: black;
    color: white;
    text-decoration: none;
    font-size: 20px;
    width: 90%;
    border-radius: 8px;
    display: flex;
    margin: 20px auto 0;
    bottom: 40px;
    height: 60px;
    justify-content: center;
    align-items: center;
}

.profileLink:hover {
    background-color: #D9D9D9;
}

.profileLink:hover a {
    color: #D3A11F !important;
}

.profileLink a {
    color: white;
    text-decoration: none;
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}


/* Spotlight Section */

.spotlight-slider {
    min-height: 260px;
}

img.spotlight-image {
    width: 40%;
    height: auto;
}

.spotlight-slider .date {
    font-size: 14px;
    color: rgb(105, 105, 105);
    font-weight: 300;
}

.spotlight-meta {
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    color: black;
    margin-top: 18px;
    color: rgb(211, 161, 31);
}

.spotlight-meta:hover {
    color: #000;
}

.watch-link {
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    position: relative;
    color: black
}

.watch-link>* {
    font-size: 14px;
    font-weight: 300;
    color: black;
    text-decoration: none;
}

.sectionContent.full-width {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 0px;
    margin: 20px 0;
}

#spotlight .sectionContent.full-width {
    margin-bottom: 45px;
}

.sliderContent.spotlight-slider {
    padding: 10px;
}

.watch-link>div {
    display: inline;
    position: relative;
}

.watch-link div:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 1px;
    display: block;
    background: #666666;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.spotlight-slider:hover .watch-link>div:after {
    width: 100%;
    left: 0;
}

/* Jobs Section */


.sliderContent.job-slider {
    min-height: 320px;
}

.job-slider .location>* {
    font-size: 18px;
}

a.employer-logo img {
    width: 90px;
    height: 100%;
    object-fit: cover;
}

.employer-logo {
    margin-bottom: 10px;
    height: 90px;
}

.employer-title {
    font-size: 18px;
    margin-bottom: 12px;
    font-weight: 400;
}

.employer-title a {
    color: black;
    text-decoration: none;
}

.employer-title:hover a {
    color: #D3A11F
}

.job-title {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 15px;
}

.left-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    flex-wrap: wrap;
    width: min-content;
}

.left-badge button {
    margin-bottom: 5px;
}

.right-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background: black;
    font-size: 13px;
    font-weight: 300;
    padding: 6px 20px;
    border-radius: 50px;
}

.right-badge a {
    color: white;
    text-decoration: none;
}


/* Ad Agencies Section */

.open-jobs-btn {
    background: black;
    color: white;
    text-decoration: none;
    font-size: 15px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    margin: 20px auto 0;
    bottom: 40px;
    height: 55px;
    justify-content: center;
    align-items: center;
}

.open-jobs-btn:hover {
    background-color: #D9D9D9;
}

.open-jobs-btn:hover a {
    color: #D3A11F !important;
}

.open-jobs-btn a {
    color: white;
    text-decoration: none;
    width: 100%;
    flex: 1
}

.adagencies-slider .employer-title {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 10px;
    flex-grow: 1;
}

/* Mentor Section */

.mentors-section {
    display: flex;
    justify-content: space-around;
    padding: 0 20px 20px;
    gap: 10px;
}

.home-resources .mentors-section {
    display: block;
}

.mentor-container {
    display: flex;
    justify-content: center;
}

.mentor img {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: auto;
    z-index: 0;
}

.mentor {
    display: flex;
    transition: all 0.2s ease;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    text-align: center;
    padding: 10px;
    height: 195px;
    width: 195px;
    position: relative;
    background-color: #000;
    z-index: 0;
}

.home-resources .mentor {
    margin: 0 auto;
    width: auto;
}

.mentor span {
    color: #fff;
    font-family: 'Alta';
    font-weight: 600;
    font-size: 32px;
    z-index: 1;
}

.publications-slider img:hover {
    transform: scale(1.3);
    z-index: 1;
}

.mentor:hover {
    transform: scale(1.1);
    z-index: 1;
}

.page-mentors .mentor {
    border: 2px solid #fff;
}

.page-mentors .mentor:hover {
    transform: scale(1.1);
    box-shadow: 3px 3px 5px #afafaf;
    z-index: 1;
}

.page-mentors .mentor span {
    font-size: 48px;
}

@media (max-width:1200px) {
    .sliderContent {
        padding: 30px 5px;
    }

}

@media (max-width:768px) {
    .mentors-section {
        justify-content: center;
        flex-wrap: wrap;
    }

    .mentor {
        width: 45%;
    }

    .home-resources .mentor {
        width: auto;
    }
}

@media (max-width:400px) {
    .mentor {
        width: 75%;
        margin-bottom: 20px;
    }

    .location>* {
        font-size: 15px;
    }

    .job-location>* {
        font-size: 20px;
    }
}

/* Publications Section */
.publications-slider a {
    display: flex;
}

.sectionContent.publication-section {
    padding: 0px 10px;
}

.publications-slider {
    text-align: center;
}

img.publication-image {
    width: 100%;
    max-width: 410px;
    object-fit: cover;
    transition: all 0.2s ease;
}

.publication-section .row>div:nth-child(3n-1) .publications-slider a {
    justify-content: center;
}

.publication-section .row>div:nth-child(3n) .publications-slider a {
    justify-content: end;
}


/* Cities Section */

.city-banner-inner .bg-banner {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    background-size: cover;
}

.city-banner-inner {
    height: 623px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.city-banner-inner .inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding: 25px 30px;
    color: white;
}

.city-banner-inner .title {
    font-size: 18px;
    margin: 0 0 2px;
    position: relative;
    display: inline-block;
}

.city-banner-inner .number {
    font-size: 14px;
}

.city-banner-inner:hover .bg-banner {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}

.sectionContent.featured-cities {
    background: white;
    margin: 20px 10px;
    padding: 10px;
}

.job-banner-small .city-banner-inner {
    height: 300px;
}

.city-banner-inner {
    margin-bottom: 20px;
}

.city-banner-inner:hover .title:before {
    width: 100%;
    left: 0;
    background: white;
}

.city-banner-inner .title:before {
    content: "";
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 0;
    height: 1px;
    background-color: #202124;
    -webkit-transition: all .3s ease-in-out .3s;
    -o-transition: all .3s ease-in-out .3s;
    transition: all .3s ease-in-out .3s;
}

@media screen and (max-width:800px) {
    .city-banner-inner {
        max-height: 300px
    }
}

@media (max-width:768px) {

    .publication-section .publications-slider a {
        justify-content: center !important;
    }

}

/* Contact Section */

.contact-section {
    background: black;
    background-image: url('../assets/images/feather.png');
    color: white;
    background-position: 100% -55px;
    background-repeat: no-repeat;
    background-size: 50% auto;
    padding: 100px;
}

.contact-section .title {
    font-size: 36px;
}

.contact-btn {
    display: flex;
    text-align: center;
    text-decoration: none;
    justify-content: center;
}

.contact-btn a {
    margin-top: 20px;
    text-decoration: none;
    background: white;
    color: black;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 15px 30px;
    -webkit-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    transition: background-color .3s ease;
}

.contact-btn a:hover {
    background: #d3a11f;
}

.ticker {
    width: 100%;
    height: 60px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    background-color: white;
    color: black;
    display: flex;
    margin-top: 30px;
}

.ticker-text {
    display: flex;
    flex-direction: row;
    animation: ticker 30s linear infinite;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

@keyframes ticker {
    from {
        transform: translateX(100%);
        /* Start from the right edge */
    }

    to {
        transform: translateX(-100%);
        /* Move to the left edge */
    }
}


@media (max-width:768px) {
    .contact-section {
        padding: 60px 40px 60px 40px;
    }

    .contact-section .title {
        font-size: 27px;
    }
}

@media (max-width:500px) {
    .contact-section {
        background-position: bottom center;
        background-size: contain;
    }

    .contact-section .title {
        font-size: 32px;
        font-weight: 400;
    }

    .creative-section .sectionHeader {
        justify-content: center;
        gap: 0px 50px;
    }
}

.btn.btn-dark.btn-narrow {
    padding: 0px 5px;
    border-radius: 3px;
}

.wrapper {
    max-width: 100%;
    overflow: hidden;
    height: 60px;
    background-color: white;
    margin-top: 0px;
}

.marquee {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    animation: marquee 480s linear infinite;
    height: 100%;
    line-height: 1;
    margin-top: 5px;
}

.marquee p {
    display: inline-block;
    font-family: 'Jost', Arial, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 0;
    margin-top: 5px;
    position: relative;
}

.marquee p .bullets-container {
    margin: 0px 100px;
    position: relative;
}

.marquee p .bullets {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 900;
    position: absolute;
    left: -90px;
    top: 5px;
}

.marquee p .h1 {
    font-size: calc(1.375rem + 1.5vw);
    margin-right: 10px;
}

@keyframes marquee {
    0% {
        transform: translate3d(-27.5%, 0, 0);
    }

    100% {
        transform: translate3d(-72.5%, 0, 0);
    }
}

@media (max-width: 767px) {
    .creative-section .sectionTitle {
        text-align: center;
    }
}

@media (max-width: 1024px) and (min-width: 900px) {
    .banner {
        padding-top: 95px;
    }
}

.home-page .subHeading.motive,
.footer .subHeading.motive {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    flex-wrap: wrap;
    margin: 20px auto;
    justify-content: center;
    align-items: center;
}

.home-page .subHeading a,
.footer .subHeading a {
    color: #fff;
    margin: 0px;
}

.home-page .subHeading a:hover,
.footer .subHeading a:hover {
    color: #d3a11f;
}

.home-page .banner .subHeading,
.footer .banner .subHeading {
    margin-bottom: 20px;
}

.home-page #jobs {
    margin-top: 0px;
}

@media (max-width: 991px) {

    .mentor span {
        font-size: 40px;
    }

    .home-page #jobs {
        margin-top: 20px;
    }
}


@media (max-width: 499px) {

    .mentor span {
        font-size: 48px;
    }
}

.flip-card-front .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 10px;
    margin: 0;
    padding: 0;
}

.home-page .sectionContent swiper-container.adagencies {
    height: 350px;
}

.home-page .sectionContent {
    position: relative;
}

.slider-loader {
    position: absolute;
    background-color: #000;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    left: 0px;
    right: 0px;
    z-index: 2;
    overflow: hidden;
}

.slider-loader .item {
    background-color: #fff;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .slider-loader .item {
        width: 100% !important;
    }
}

.home-page #creatives .sectionContent {
    min-height: 400px !important;
}

.home-page #creatives .sectionContent .slider-loader {
    column-gap: 30px;
    width: calc(100% - 100px);
    height: calc(100% - 20px);
    margin: 0px 50px;
}


.home-page #mentors .sectionContent {
    min-height: 215px !important;
}

.home-page #jobs .sectionContent {
    min-height: 340px !important;
}

.home-page #jobs .sectionContent .slider-loader {
    column-gap: 30px;
    width: calc(100% - 100px);
    height: calc(100% - 20px);
    margin: 0px 50px;
}

.home-page #spotlight .sectionContent {
    min-height: 240px !important;
}

.home-page #spotlight .sectionContent .slider-loader {
    column-gap: 30px;
    width: calc(100% - 28px);
    height: calc(100% - 0px);
    margin: 0px 14px;
}

.home-page #agencies .sectionContent {
    min-height: 370px !important;
}

.home-page #agencies .sectionContent .slider-loader {
    column-gap: 30px;
    width: calc(100% - 100px);
    height: calc(100% - 20px);
    margin: 0px 50px;
}

.home-page #publications .sectionContent {
    min-height: 540px !important;
}