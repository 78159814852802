.package-container {
    padding: 20px 10px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #ECEDF2;
    margin-bottom: 20px;
    background: #fff;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        font-size: 20px;
        font-weight: 500;
        height: 50px;
        text-align: center;
        line-height: 1;
    }

    .price {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: center;
    }

    img {
        width: 100%;
        margin-bottom: 30px;
    }

    .subtitle {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }

    ul {
        list-style: none;
        font-size: 16px;
        padding: 0;
        color: #696969;
    }

    li {
        margin-bottom: 10px;
        list-style: disc;
        font-size: 10pt;
        line-height: 16px;
        font-weight: 400;
    }

    button {
        padding: 17px 25px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 500;
        display: block;
        margin: 30px auto 0;
    }

    &:hover {
        border: 1px solid black;

        button {
            background: black;
            color: white;
        }
    }

    @media (max-width: 786px) {

        .title,
        .subtitle {
            font-size: 18pt;
            font-weight: 700;
            line-height: 28px;
        }
    }
}