.right-sidebar {

    .widget {
        padding: 10px;
        background: white;
        border-radius: 10px;
        margin-bottom: 30px;
    }

    .widget-header {
        padding: 10px;
        border-radius: 10px;
        background-color: #d3a11f;
    }

    .widget-title {
        color: #fff;
        font-weight: 500;
        font-size: 18px;
    }


    .widget-content {
        margin-top: 15px;
    }

    .widget-content .item-options {
        display: flex;
        gap: 10px;
        margin-bottom: 15px;
    }

    .item-options a {
        background: black;
        color: white;
        padding: 5px 10px;
        font-size: 10px;
        border-radius: 5px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .item-options span.bp-separator {
        display: none;
    }

    .widget-content .item-list {
        padding: 0;
        list-style: none;
    }

    .item-list li.vcard {
        display: flex;
        gap: 10px;
        padding: 15px 0;
        border-bottom: 1px solid #e1e1e1;
    }

    .item-list li.vcard .item-avatar img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }


    .item-list li.vcard .item-title {

        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .item-content {
            font-size: 16px;
        }

        a {
            color: black;
            font-size: 16px;
            margin-right: 5px;
        }
    }

    .item-list li.vcard .item-meta a {
        font-size: 13px;
        background: black;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
    }
}

.react-calendar {
    font-family: 'Jost';
    padding: 5px;
    border: none;
    font-size: 15px;
    width: 100%;
    background: black;
    color: white;
    border-radius: 5px;

    button {
        color: white;
    }

    .react-calendar__tile--now {
        background: black;
        border: 2px solid white;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus,
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
        background: var(--color-primary);
    }

    button.react-calendar__navigation__label {
        font-size: 1.13rem;
        padding: 0;
        line-height: 1.1em;
    }

    button.react-calendar__navigation__arrow {
        padding: 0px;
    }

    .react-calendar__tile--active {
        background: #d3a11f;
    }

    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
        display: none;
    }
}

.item.new-member-widget {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.item.new-member-widget>* {
    line-height: 1 !important;
}

.item.new-member-widget .item-title {
    width: 35%;
}

.item.new-member-widget .item-industry-experience {
    width: 35%;
    font-size: 10pt;
}

.item.new-member-widget .item-meta {
    width: 30%;
    text-align: right;
}

.item-title a,
.item-meta a {
    color: #000 !important;
    font-weight: 500 !important;
}

.item-meta a .activity {
    color: #fff !important;
    font-weight: 500 !important;
}

.item-title a:hover,
.item-meta a:hover,
.item-meta a:hover .activity {
    color: #d3a11f !important;
}

@media (max-width: 992px){
    .right-sidebar .item-list li.vcard .new-member-widget .item-meta a{
        padding: 5px;
    }
}

@media (max-width: 767px){
    
}