.packages-table {
    color: #696969;
    font-size: 20px;

    .lists-info {
        padding: 0;
        list-style: none;
        margin: 0;
    }

    thead {
        color: black;
    }

    tbody td {
        font-weight: 300;
    }

    .packageName {
        color: black;
        text-decoration: underline;
    }

    span.action {
        &.active {
            color: #79b530;
        }
        &.expired{
            color:red
        }
    }
}