.form-inp {
    padding: 15px;
    border: none;
    margin-bottom: 30px;
    border-radius: 3px;
    font-size: 15px;
}

.contact-form {
    margin-top: 3em;
}

button.submit-btn {
    background: var(--color-primary);
    color: white;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: none;
    transition: all 0.2s ease-in;
}

button.submit-btn:hover {
    background: white;
    color: black;
}

.comment-form-rating .review-stars {
    color: #e1e1e1;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 13px;
    letter-spacing: 6px;
}

.comment-form-rating .review-stars.filled {
    color: #FFC78B;
    position: absolute;
    top: 0;
    left: 0;
}

.comment-form-rating .review-stars li {
    float: left;
}

.reset-button-default #respond {
    border: 1px solid #ECEDF2;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 45px 50px 50px;
    margin-top: 55px;
}