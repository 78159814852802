$primary-color: #d3a11f;

:root {
  --color-primary: #{$primary-color};
  --color-secondary: #ededed;
  --color-gray: #777777;
  --bs-primary-rgb: 211, 161, 31;
  --bs-btn-active-bg: #{$primary-color};
  --bs-btn-bg: #{$primary-color};
}

@font-face {
  font-family: 'Jost';
  src: url(assets/fonts/Jost/Jost-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Alta';
  src: url(assets/fonts/Alta_Typeface/Alta_regular.woff) format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Alta';
  src: url(assets/fonts/Alta_Typeface/Alta_light.woff) format('woff');
  font-weight: light;
}

@font-face {
  font-family: 'Alta';
  src: url(assets/fonts/Alta_Typeface/Alta_caption.woff) format('woff');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'JOST', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  object-fit: cover;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: black;
  text-decoration: none;
}

a.link-dark {
  color: black !important;
}

a.link-dark:hover {
  color: var(--color-primary) !important;
}

a.link-hover-dark:hover {
  color: black !important;
}

a.link-gray {
  color: var(--color-gray) !important;
}

a.link-gray:hover {
  color: black !important;
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #858585;
  cursor: text;
}

[contenteditable=true]:focus-visible {
  outline: none;
  border: 0px
}

.form-control:focus {
  outline: none;
  border: none;
  box-shadow: none;
}


.btn-theme {
  background-color: var(--color-primary);
  color: white;
}

.background-primary {
  background-color: var(--color-primary);
}

.btn-dark {
  background-color: black;
  color: white;
}

.btn-dark.btn-outline {
  border: 1px solid white;
}

.btn.btn-hover-primary:hover {
  background-color: var(--color-primary) !important;
  color: black !important;
}

.btn-secondary {
  background-color: var(--color-secondary);
  color: black;
  border-color: var(--color-secondary);
}


.btn-gray {
  background-color: var(--color-gray);
  color: white;
  border-color: var(--color-gray);
}


.btn-secondary:hover {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
  color: #000;
}

.ls-3 {
  letter-spacing: 3px;
}

.alert {
  font-size: 20px;
}

.alert-warning {
  background: #000;
  color: var(--color-primary);
}

.alert-info {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}

.MuiTooltip-tooltip {
  text-transform: capitalize;
}


li.page-item {
  width: max-content;
  min-width: 25px;
  text-align: center;
  user-select: none;
  -moz-user-select: none;
}

.pagination {
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
}

.page-link {
  color: var(--color-primary);
  padding: 5px;
  font-size: 14px;
}

.active .page-link {
  background: var(--color-primary);
  border-color: var(--color-primary);

  &:hover {
    color: white;
  }
}

.loader {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.page-link:hover {
  color: var(--color-primary);
}

.shortlist-btn {
  opacity: 1;
  border: 0px;
  background: black;
  color: white;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 5px;
  transition: all 0.3s ease;
  padding: 7px;
  line-height: 1;
}

.sliderContent:hover .shortlist-btn {
  opacity: 1;
}

.shortlist-btn:hover {
  background: var(--color-primary);
}

.shortlist-btn.active {
  opacity: 1;
  background: var(--color-primary);
}

.p-relative {
  position: relative;
}

.offset-top {
  margin-top: 50px;
}


@media (min-width: 576px) {
  .container {
    max-width: 650px;
  }

  .creatives-directory .container {
    max-width: 100%;
    padding: 0px 20px !important;
  }
}

@media (min-width: 800px) {
  .container {
    max-width: calc(100% - 40px);
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: calc(100% - 40px);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// reviews styling
.average-rating {
  font-size: 22px;
  margin-top: 30px;
}

.average-rating>div {
  background: #eeeeee;
  border-radius: 15px;
  padding: 0 15px;
  cursor: pointer;
}

.comment-list {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1.75;
}

.bypostauthor {
  box-sizing: border-box;
}

.comment-list .the-comment {
  padding: 0 0 20px;
}

.comment-list>li:last-child>.the-comment:last-child {
  padding-bottom: 0;
}

.comment-list div.avatar {
  width: 75px;
  padding-right: 15px;
  float: left;
}

.comment-list img.avatar {
  width: 55px;
  height: auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 0;
}

.comment-list .comment-box {
  overflow: hidden;
}

.comment-list .comment-box .star-rating {
  margin-top: 3px;
}

.review-avg {
  text-align: center;
  line-height: 30px;
  min-width: 30px;
  font-size: 13px;
  font-weight: 500;
  color: #202124;
  background-color: #daa520;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.star-rating>* {
  display: inline-block;
  vertical-align: middle;
}

.review-stars-rated {
  position: relative;
  overflow: hidden;
  line-height: 1;
  text-align: left;
}

ul.review-stars {
  list-style-type: none;
  display: flex;
  gap: 4px;
  padding-left: 5px;
}

.cursor-pointer {
  cursor: pointer;
}