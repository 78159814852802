.page-mentors {

    .mentor {
        width:95%;

        span {
            font-family: Alta;
            font-size: 3rem;
            font-weight: 600;
            line-height: 45px;
            text-shadow: 1px 0 #fff;
        }

        // @media (max-width:1300px) {
        //     span {
        //         font-size: 2rem;
        //     }
        // }
    
        // @media (max-width:900px) {
        //     span {
        //         font-size: 1.5rem;
        //     }
        // }

        // @media (max-width:576px) {
        //     span {
        //         font-size: 3rem;
        //     }
        // }
    }

    .mentor img:hover {
        transform: none;
    }

    .page-title {
        color: var(--color-primary);
        text-transform: uppercase;
        text-align: center;
        font-size: 60px;
        font-weight: 400;
    }

    .page-subtitle {
        color: white;
        text-align: center;
        font-size: 20px;
        margin-top: 0px;
    }


    @media (max-width: 768px) {
        .mentor {
            width: 100%;
        }

        .page-title {
            font-size: 45px;
        }
    }
}