.site-logo img {
    width: 70px;
    height: 70px;
}

a.site-logo {
    min-width: 200px;
    display: flex;
    gap: 5px;
    align-items: center;
    font-family: ALTA;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: bold;
    line-height: 1em;
    letter-spacing: 0.05em !important;
    color: #000 !important;
}

.btn-showmenu>* {
    background-color: #202124;
    display: inline-block;
    height: 2px;
    width: 20px;
    margin: 3px 0;
}

.btn-showmenu .inner2 {
    width: 25px;
}

.btn-showmenu .inner3 {
    width: 15px;
}

a {
    color: var(--color-primary);
}

a.black {
    color: black;
}

a:hover {
    color: black;
}

a.active>.menu-link-btn {
    color: var(--color-primary);
}

.nav-item {
    display: inline-block;
}

.right-menu .post-job-link {
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: black;
    padding: 8px 10px !important;
    font-size: 1.1rem;
    border-radius: 8px;
    margin: 0px;
}

.right-menu .post-job-link:hover {
    background: black;
    color: var(--color-primary);
}

.right-menu .login-btn:hover {
    border: 2px solid black;
    background: var(--color-primary);
}

.right-menu .login-btn {
    padding: 6px;
    margin-left: 5px;
    font-size: 20px;
    font-weight: 400;
    border-radius: 8px;
    border: 2px solid white;
    text-transform: none;
}

/* Drawer Styles */
.drawer-menu-link {
    color: rgb(151, 151, 151);
    font-size: 17px;
    padding: 12px 20px;
}

.drawer-menu-link:hover {
    color: white;
}

.drawer-box {
    padding: 20px;
}

.drawer-menu-link {
    color: rgb(151, 151, 151) !important;
    font-size: 17px;
    padding: 12px 20px;
}

.drawer-menu-link:hover {
    color: white;
}

.drawer-box {
    padding: 20px;
}

.social-box {
    padding: 0 20px;
    display: flex;
    gap: 15px;
    align-items: center;
}

.drawer-box .social {
    background: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: background-color 0.5s ease;
    font-size: 21px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drawer-box .social:hover {
    background: var(--color-primary);
    color: white;
}


.has-children:hover .dropdown-menu {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    left: 0;
    overflow-y: auto;
}

.has-children:hover .dropdown-menu:not(.logged-in-dropdown) {
    border-top: 2px solid black;
    border-radius: 0;
}

.dropdown-menu {
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    -webkit-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    left: 0;
}

ul.dropdown-list {
    list-style-type: none;
    min-width: 200px;
    padding: 0;
    padding-top: 15px;
}

ul.dropdown-list li a {
    text-decoration: none;
    font-size: 20px;
    color: black;
    width: 100%;
    padding: 7px 30px;
    display: block;
}

ul.dropdown-list li a:hover {
    color: var(--color-primary)
}

.has-children {
    position: relative;
}

ul.dropdown-list:before {
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent black;
    content: '';
    position: absolute;
    bottom: 100%;
    left: 40px;
}

.logged-in-menu {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--color-primary);
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 300;
    cursor: pointer;
}

.logged-in-menu:hover {
    color: black;
}

.logged-in-dropdown {
    left: auto !important;
    right: 0px;
    top: 65px;
    padding: 15px;
    overflow: hidden;
    max-height: calc(100vh - 75px) !important;
}

.logged-in-dropdown .dropdown-list:before {
    content: none;
}

.logged-in-dropdown ul li a {
    padding: 8px 20px;
    border-radius: 8px;
}

.logged-in-dropdown ul li:hover a {
    background: var(--color-secondary);
}

.logged-in-dropdown ul {
    padding-top: 0;
    margin-bottom: 0px;
}

.btn.btn-dark {
    border: 2px solid black;
    background-color: black !important;
    color: white !important;
}

.btn.btn-dark:hover {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary) !important;
}

.btn.btn-white {
    border: 2px solid white;
    background-color: white !important;
    color: black !important;
}

.btn.btn-white:hover {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary) !important;
}

.btn.btn-silver {
    border: 2px solid silver;
    background-color: silver !important;
    color: white !important;
}

.btn.btn-silver:hover {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary) !important;
}

.btn.btn-gold {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary) !important;
    color: white !important;
}

.btn.btn-gold:hover {
    border: 2px solid silver;
    background-color: silver !important;
}

.btn.btn-gold.hover-black:hover {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary) !important;
    color: black !important;
}

.btn.btn-gold.hover-dark:hover {
    border: 2px solid black;
    color: white !important;
    background-color: black !important;
}

.link.link-gold {
    color: var(--color-primary) !important;
}

.link.link-gold:hover {
    color: silver !important;
}

.link.link-gold.hover-black:hover {
    color: black !important;
}

.link.link-black {
    color: black !important;
}

.link.link-black:hover {
    color: silver !important;
}

.link.link-black.hover-gold:hover {
    color: var(--color-primary) !important;
}

.link.link-silver {
    color: silver !important;
}

.link.link-silver:hover {
    color: black !important;
}

.link.link-silver.hover-gold:hover {
    color: var(--color-primary) !important;
}

.link-bold {
    font-weight: 500;
}

.link-underline {
    text-decoration: underline;
}

.sidebar-menu li.menu-item a .item-name {
    flex: 1
}

.menu-item .count {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    min-height: 20px;
    transition: all 0.5s ease;
}

.menu-item a.active .count,
.menu-item a:hover .count {
    background-color: #000;
}

.center-page {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    min-width: 250px;
    min-height: 100px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    user-select: none;
}

.btn.btn-dark.btn-selected {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary) !important;
}

#reviews {
    padding: 20px 0px;
}

.rating-submitted {
    background-color: var(--color-primary);
    color: #000;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 10pt;
    display: flex;
    column-gap: 5px;
}

.rating-submitted svg {
    margin-top: 2px;
}

.btn-review {
    font-size: 12px;
    padding: 5px 15px;
}

textarea.form-control:focus {
    border: 1px solid gray;
}

@media (max-width: 539px) {
    .auth-modal .auth-body ul.role-tabs.nav.nav-tabs li {
        flex: unset !important;
        /* For Login / Register Buttons on separate rows on small screens */
    }
}

.scroll-button-span {
    background-color: #fff;
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: -1;
}

.scroll-button:hover .scroll-button-span {
    background-color: #000;
}

.page-film-festival {
    height: auto;
    min-height: 100vh;
    color: #fff;
}

.d-fccc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.d-fccr {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.d-fssc {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.d-fssr {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
}

.d-fscc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.d-fscr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.button-group {
    row-gap: 10px;
    column-gap: 10px;
}

.page-film-festival .film-festival-step {
    color: #fff;
    max-width: 90vw;
}

.page-film-festival .h1 {
    font-family: Alta;
    font-size: 60px;
    font-weight: 700;
    font-family: 'ALTA';
    letter-spacing: 0.2em;
}

.page-film-festival .h2 {
    font-family: 'Anton';
    font-size: 56px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.02em;
}

.page-film-festival .btn-home {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 10px 20px;
}

.btn-wide {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.page-film-festival .film-festival-enter {
    margin-top: 20px;
    cursor: pointer;
}

.page-film-festival .h3 {
    text-transform: uppercase;
    font-size: 21.5px;
    font-weight: 400;
    line-height: 1.15;
    letter-spacing: -0.05em;
}

.relative {
    position: relative;
}

.page-film-festival {}

.page-film-festival .film-festival-step.step-1 {
    max-width: 1000px;
}

.page-film-festival .film-festival-step.step-1 .favicon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-form {
    width: 50%;
    margin: 3em auto;
}

@media (max-width: 1023px) {
    .contact-form {
        margin: 3em 0px;
        width: 100% !important;
    }
}

@media (min-width: 767px) {
    .page-film-festival .film-festival-step.step-1 .favicon {
        position: absolute;
        right: 0px;
        top: 30px;
        justify-content: flex-end;
        max-width: fit-content;
    }

    .page-film-festival .film-festival-step.step-1 .favicon img {
        width: 80% !important;
        height: 80% !important;
    }
}

.page-film-festival .film-festival-step.step-3 {
    row-gap: 10px;
    column-gap: 10px;
}

.page-film-festival .film-festival-step.step-3 .h3 {
    margin: 10px 0px;
}

.page-film-festival .film-festival-step.step-2 .profile-edit-form {
    background-color: #fff;
    color: #000;
    margin: 20px;
    padding: 20px;
    border-radius: 20px;
}

.page-film-festival .page-title {
    color: var(--color-primary);
    text-transform: uppercase;
    text-align: center;
    font-size: 60px;
    font-weight: 400;
}

.input-required {
    color: red;
    font-weight: 500;
}

video {
    width: 100%;
    height: auto;
}

.page-film-festival .submission-form-upload {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    object-fit: contain;
    border: 1px solid black;
}

.page-film-festival video {
    min-width: 50vw;
    max-width: 100vw;
}

/* .nav-item a button {
    padding: 6px !important;
} */

.rdw-link-modal {
    left: unset !important;
    right: 35px !important;
    height: max-content !important;
}

.statistics.row>* {
    padding-left: 5px;
    padding-right: 5px;
}

nav ul.dropdown-list .list-item-text.j-fix {
    margin-left: 3px;
}

.tox.tox-tinymce {
    z-index: 0;
    min-height: 250px;
    height: 100% !important;
}

.error-text {
    color: red;
    font-weight: 900;
    font-size: larger;
}

.progress-text {
    color: darkgreen;
    font-weight: 900;
    font-size: larger;
}

.image-previews {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.icon-message {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
}

.icon-message .icon {
    cursor: pointer;
}

.icon-message .icon img {
    width: 18px !important;
    height: 18px !important;
}

.icon-message .message-container {
    display: none;
    align-items: center;
    background-color: #000;
    width: 100%;
    min-width: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.icon-message .message {
    padding: 10px;
    color: #fff;
    font-weight: 500;
    font-size: normal;
    line-height: 1.5;
}

/* .icon-message:hover .message-container, */
.icon-message.show .message-container {
    display: flex;
    min-height: 140px;
}

.icon-message:hover .message {
    display: block;
}

.image-picker .icon-message {
    justify-content: flex-start;
    align-items: center;
}


.image-picker video {
    max-width: 500px;
}

.image-picker .circular-progress-with-label .circular-progress-typography {
    font-family: "JOST", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 900;
}

.image-picker-body p {
    text-align: center;
}

@media (max-width: 375px) {
    .image-picker-body {
        justify-content: flex-start !important;
    }
}

.show-delayed {
    visibility: visible !important;
}

.agency-page-job-edit label,
.agency-page-profile label,
.creative-page-profile label,
.my-resume label {
    display: flex;
    position: relative;
    gap: 10px;
}

.agency-page-job-edit .icon-message .message-container,
.agency-page-profile .icon-message .message-container,
.creative-page-profile .icon-message .message-container,
.my-resume .icon-message .message-container {
    position: absolute;
    left: 0px;
    top: 35px;
    z-index: 2;
}

.agency-page-job-edit .icon-message .icon img,
.agency-page-profile .icon-message .icon img,
.creative-page-profile .icon-message .icon img,
.my-resume .icon-message .icon img {
    width: 20px;
    height: auto;
}

.page-messages .emoji-picker-container {
    display: block;
    position: relative;
}

.page-messages .chat-container .chat-footer aside.EmojiPickerReact.epr-main {
    top: unset !important;
    bottom: 25px !important;
    height: 350px !important;
    width: 300px !important;
    left: -30px;
}

.page-messages .emoji-exit {
    display: block;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    top: -395px;
    left: 250px;
}

.show-more {
    color: #d3a11f !important;
    text-decoration: none !important;
    position: absolute;
    bottom: -15px;
    cursor: pointer;
}

.show-more:hover {
    color: #000 !important;
}

.show-more-container {
    display: flex;
    justify-content: end;
    position: relative;
}

.show-more-less-clickable {
    color: #d3a11f !important;
    text-decoration: none !important;
    cursor: pointer;
}

.show-more-less-clickable:hover {
    color: #000 !important;
}

.edited {
    margin-left: 10px;
    padding: 2px 7px;
    font-size: 8pt;
    line-height: 1.1;
    background-color: #d3a11f;
    color: #000;
    border-radius: 5px;
    cursor: default;
    user-select: none;
    -moz-user-select: none;
}

.chat-box .job-action a {
    width: 20px;
    height: 20px;
    line-height: 20px;
}

@media (max-width: 1024px) and (min-width: 900px) {
    .header_container {
        flex-direction: column !important;
    }

    .header_container .right-menu {
        flex-basis: 100%;
        max-width: 100%;
    }

    .header_container .left-logo {
        flex-basis: 50%;
        max-width: 50%;
    }

    .agency-dashboard-container {
        padding-top: 95px;
    }
}

@media (max-width: 440px) {

    .header_container .left-logo {
        flex-basis: 65%;
        max-width: 65%;
    }

    .header_container .right-menu {
        flex-basis: 35%;
        max-width: 35%;
    }

    .left-logo a.site-logo {
        min-width: 100%;
        font-size: 24px;
    }
}

.logged-in-menu .username {
    width: 115px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.jobs-list-container .job-location.location {
    font-size: 20px;
    margin-right: 10px;
}

.jobs-list-container .job-location.location a,
.jobs-list-container .job-location.location span {
    font-size: 20px;
    font-weight: 400;
}

.jobs-list-container .job-location.location svg {
    font-size: 20px;
}

.job-post-badge {
    font-size: 16px;
    font-weight: 500;
    background-color: #d6d6d6;
    color: #000;
    margin: 10px 10px 0px 0px;
    padding: 10px;
    min-height: 40px;
}

.job-post-badge.gold {
    background-color: #d3a11f;
    color: #fff;
}

.job-post-badge.red {
    background-color: red;
    color: #fff;
}

.agency-page-jobapplicants .job-post-badge {
    font-size: 12px;
    padding: 5px;
    line-height: 1em;
    font-weight: 500;
}

.agency-page-jobapplicants .btn.btn-gold.show-more-less {
    font-size: 12px;
    padding: 3px 5px;
    line-height: 1em;
    font-weight: 500;
}

.image-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: min-content;
}

.image-loader img {
    border-radius: 100%;
}

.agency-page-myjobs .image-loader img {
    border-radius: 5px;
}

.agencies-page .image-loader {
    height: 100%;
}

.agencies-page .image-loader img {
    margin: 0px;
}

.image-loader .loader-image {
    position: absolute;
}

.video-upload-indicator {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 10px 0px;
}

.video-upload-indicator h6 {
    line-height: 1;
    margin: 0;
}

.btn.btn-apply.active.internal {
    cursor: default;
}

.btn.btn-apply.active.internal:focus,
.btn.btn-apply.active.internal:active {
    border: 2px solid #d3a11f;
}

.btn.btn-apply.active.external {
    cursor: pointer;
    background-color: #d3a11f;
    color: #000;
}

.btn.btn-apply.active.external:hover {
    background-color: #d3a11f;
    color: #fff;
}

.btn.btn-apply.expired {
    cursor: default;
    background-color: #d3a11f;
    color: #000;
    border: 2px solid #d3a11f;
}

.btn.btn-apply.expired:hover {
    background-color: #d3a11f;
    color: #000;
    border: 2px solid #d3a11f;
}

.password-reset {
    flex-direction: column;
}

.forgot-password,
.password-reset {
    max-width: 50%;
    margin: 0px auto;
}

@media (max-width: 768px) {

    .forgot-password,
    .password-reset {
        max-width: 100%;
    }
}

.job-table-actions-inner.status {
    text-transform: capitalize;
    text-align: center;
}

.refresh-preview-container {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 10px;
}

.refresh-preview-container .btn.btn-gold.refresh-preview.disabled {
    background-color: #d6d6d6;
}

.agency-dashboard-container .job-table-info-content {
    min-width: 200px;
}

.agency-dashboard-container .job-location.location {
    flex-wrap: wrap;
}

.creatives-directory .job-location.location>* {
    font-size: 18px !important;
    font-weight: 400 !important;
}

.login-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-section * {
    font-family: "JOST", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.image-dialog {}

.image-dialog .image-container {
    align-items: start;
    background-color: #ccc;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: start;
    overflow: auto;
    position: relative;
    width: 100vw;
}

.image-dialog .image-container .quit {
    position: fixed;
    right: 20px;
    top: 20px;
    background-color: #d3a11f;
    color: #000;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    cursor: pointer;
    padding: 5px;
}

.image-dialog .image-container .quit:hover {
    color: #fff;
}

.agency-dashboard-container .candidate-list {
    padding-top: 30px !important;
}

.agency-dashboard-container .applicant-item {
    position: relative;
}

.agency-dashboard-container .applicant-item .remove-recent {
    position: absolute;
    right: 5px;
    top: 5px;
}

.agency-dashboard-container .applicant-item .remove-recent .icon {
    color: #000;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    cursor: pointer;
}

.agency-dashboard-container .applicant-item .remove-recent .icon:hover {
    color: #d3a11f
}

.slug input[type="text"] {
    font-size: 16px !important;
    line-height: 24px;
    font-weight: normal;
}

.agency-page-myjobs.tabular {}

.action-button a,
.action-button button {
    color: black !important;
}

.action-button a:hover,
.action-button button:hover {
    color: var(--color-primary) !important;
}

.action-button a:hover svg,
.action-button button:hover svg {
    color: black !important;
    fill: black !important;

}

.agency-page-myjobs.tabular .badge {
    min-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.job-table-info-content-date-expiry {
    min-width: 100px;
}

.agency-page-myjobs.tabular .job-location.location a {
    font-size: 16px !important;
}


.agency-page-myjobs.tabular .badge {
    width: 120px !important;
}

.job-metas .title-wrapper.job-status {
    font-size: 12pt;
}

.job-status-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sliding-message {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    min-width: 300px;
    height: auto;
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 0px;
    font-weight: 400;
    font-size: 20px;
    font-family: "JOST", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    top: 145px;
    right: -100%;
    transition: right 1s;
    z-index: 1500;
}

.sliding-message.active {
    padding: 20px 30px;
    right: 20px;
}

@media(max-width: 767px) {
    .sliding-message {
        width: calc(100% - 40px) !important;
    }
}


.no-transform {
    text-transform: none !important;
}

.badge.bg-secondary {
    background-color: #d3d3d3;
}

.badge.bg-hired {
    background-color: #34A853;
}

.badge.bg-pending {
    background-color: #0dcaf0;
}

.badge.bg-interested {
    background-color: #000000;
}

.badge.bg-recommended {
    background-color: #090070;
}

.badge.bg-shortlisted {
    background-color: #d3a11f;
}

.badge.bg-not-aligned {
    background-color: #f40606;
}

.search-bar {
    position: relative;
}

.search-bar .clear-search {
    position: absolute;
    bottom: 0px;
    right: 20px;
    cursor: pointer;
    color: #000000;
}

.search-bar .clear-search:hover {
    color: #d3a11f
}

.job-table-row {
    position: relative;
    height: 200px;
}

.job-table-row .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.job-table-row .close-modal svg {
    fill: #000000 !important;
}

.job-table-row .close-modal:hover {
    color: #d3a11f;
}

.job-table-row .close-modal:hover svg {
    fill: #d3a11f !important;
}

.custom-editor-container {
    position: relative;
    min-height: 240px;
}

.custom-editor-container .circular-progress {
    position: absolute;
    left: 0px;
    top: 0px;
}

.my-jobs-search-container {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
}

.my-jobs-search-container input[type="text"] {
    flex: 1;
}

@media(max-width: 767px) {
    .search-box-common {
        gap: 10px;
    }
}

.search-box-common .search-btn button {
    font-size: 16px;
}

.search-box-common .search-bar input {
    font-size: 16px;
}

.search-box-common .search-bar input {
    font-size: 16px;
}

.search-box-common .search-bar .search-icon,
.search-box-common .search-bar .reset-search-icon {
    width: 20px;
    height: 20px;
    top: 11px;
}

.notes-action .btn {
    color: black;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    display: inline-block;
    background-color: #ededed;
    transition: all 0.3s ease-in-out 0s;
    font-size: 16px;
    position: relative;
    margin-left: 5px;
}

.notes-action .btn:hover {
    background-color: var(--color-primary) !important;
    color: black !important;
}

.search-level2 {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 0px 0px 0px 0px;
}

@media (max-width: 767px) {
    .search-level2 {
        flex-direction: column !important;
        padding: 0px !important;
    }
}

.creative-search .search-level2 {
    padding: 0px;
}

.search-level2 .search-title {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    margin-top: 12px;
    margin-left: 6px;
}

.search-level2 form {
    width: 100%;
}

.link-svg-dark svg {
    color: #000;
}

.link-svg-dark:hover svg {
    color: var(--color-primary);
}

.advisor-tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    word-wrap: normal;
    max-width: 75px;
    text-align: center;
    text-transform: none;
}

.profile-content .react-photo-album .react-photo-album--row {
    gap: 10px !important;
    justify-content: start !important;
    flex-wrap: wrap !important;
}

.profile-content .portfolio-item {
    width: 200px !important;
}

@media(max-width: 767px) {
    .profile-content .react-photo-album .react-photo-album--row {
        justify-content: center !important;
    }

    .profile-content .portfolio-item {
        width: 150px !important;
    }
}

h2.community-subtitle ul,
h2.community-subtitle ul li {
    list-style: none;
    display: inline-block;
}

.agency-page-myjobs.tabular.dialog td {
    min-width: 150px;
}

.dialog .close-modal,
.common-modal-dialog .MuiPaper-root .close-modal {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    z-index: 2;
}

.dialog .close-modal:hover,
.common-modal-dialog .MuiPaper-root .close-modal:hover {
    color: #d3a11f;
}

.image-cropper-container {
    position: relative;
    height: 420px;
}

.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
}

.controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 40px;
    display: flex;
    align-items: center;
}

.slider {
    padding: 22px 0px;
}

.zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    background: #3f51b5;
    width: 100%;
}

.zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.mobile-view {
    display: none;
}

.desktop-view {
    display: inline-block;
}

@media (pointer:none),
(pointer:coarse) {
    .mobile-view {
        display: block;
    }

    .desktop-view {
        display: none;
    }
}

div.welcome-lounge {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin: auto;
}

div.welcome-lounge .user_image {
    border-radius: 100%;
    height: 33%;
    object-fit: cover;
    position: absolute;
    width: 33%;
    margin-right: 10px;
    margin-bottom: 10px;
}

div.welcome-lounge .user_info {
    position: absolute;
    bottom: 3%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
}

div.welcome-lounge .user_info div {
    color: #fff;
    font-variant: small-caps;
    font-size: 1.25em;
    line-height: 1.25em;
}

@media (max-width: 767px) {

    div.welcome-lounge .user_info div {
        font-size: 16px !important;
        line-height: 1.25em !important;
    }
}

@media (max-width: 1024px) {

    div.welcome-lounge .user_info div {
        font-size: 18px !important;
        line-height: 1.25em !important;
    }
}

@media (orientation: landscape) and (max-width: 1024px) {

    div.welcome-lounge .user_info div {
        font-size: 18px !important;
        line-height: 1.25em !important;
    }
}

.image-placeholder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    width: 100px;
    height: 100px;
    margin: 0;
    padding: 0;
}

.user-avatar .image-placeholder,
.chat-item .image-placeholder,
.chat-box .image-loader,
.post-item .image-placeholder,
.users-list .image-placeholder {
    width: 40px;
    height: 40px;
}

.chat-box img.candidateLogo {
    margin: 0;
}

.logged-in-menu .image-placeholder {
    width: 50px;
    height: 50px;
}

.sliderContent.members-list .image-placeholder {
    margin: 0 auto 15px;
}

.image-placeholder .placeholder-item {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-size: 48px;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #000;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    margin: 0;
    padding: 0;
}


.user-avatar .image-placeholder .placeholder-item,
.chat-item .image-placeholder .placeholder-item,
.post-item .image-placeholder .placeholder-item,
.users-list .image-placeholder .placeholder-item {
    font-size: 24px;
}

.logged-in-menu .image-placeholder .placeholder-item,
.employer-logo .image-placeholder .placeholder-item,
.creative-page-agency-shortlist .image-placeholder .placeholder-item {
    font-size: 32px;
}

.image-loader .image-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

img.agency-image-loader {
    border-radius: 100% !important;
}

.common-modal-actions-apply-now {
    justify-content: center !important;
    padding: 0 !important;
}

.common-modal-actions-apply-now .btn.btn-apply {
    background-color: #d3a11f !important;
    color: #000 !important;
    border: 2px solid #d3a11f !important;
    padding: 10px 30px !important;
}

.common-modal-actions-apply-now .btn.btn-apply:hover {
    background-color: #d3a11f !important;
    color: #fff !important;
    border: 2px solid #d3a11f !important;
}

.common-modal-actions-apply-now .btn.btn-apply:active {
    border: 2px solid #000 !important;
}

.btn.btn-dark.registration-success:hover {
    background-color: #000 !important;
    color: #d3a11f !important;
    border: 2px solid #000 !important;
}

.close-modal.registration-success {
    right: 35px !important;
    top: 45px !important;
}

@media (max-width: 767px) {
    .dialog-registration-success .MuiDialog-paper {
        width: calc(100% - 63px) !important;
        max-width: calc(100% - 63px) !important;
    }
}

.flex-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    margin: 30px;
    gap: 30px;
}

.search-suggestions {
    display: flex;
    padding: 10px;
    gap: 10px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    border: 2px solid #000;
    top: 45px;
    left: 50px;
    min-width: 200px;
    width: max-content;
}

@media(max-width: 767px) {
    .search-suggestions {
        max-width: calc(100vw - 150px);
    }
}

.search-suggestions ul,
.search-suggestions ul li {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    padding: 0;
    line-height: 1;
    border: 0;
    outline: 0;
    overflow: hidden;
    display: block;
    width: 100%;
}

.search-suggestions ul li {
    color: #000;
    cursor: pointer;
    padding: 10px;
}

.search-suggestions ul li:hover,
.search-suggestions ul li.active {
    background-color: #000 !important;
    color: #fff !important;
}

.download-profile-pdf-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.profile-header .friendship-request-status {
    font-size: 20px;
    font-weight: 400;
}


.community-friends .friendship-request-status {
    font-size: 16px;
    font-weight: 400;
}

.flex-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}



.spotlight-reel-section {
    display: flex;
    justify-content: space-around;
    padding: 0 20px !important;
    gap: 10px;
}

.home-resources .spotlight-reel-section {
    display: block;
}

.spotlight-reel {
    display: flex;
    transition: all 0.2s ease;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    text-align: center;
    padding: 10px;
    height: 195px;
    width: 195px;
    position: relative;
    background-color: #000;
    z-index: 0;
}

.home-resources .spotlight-reel {
    margin: 0 auto;
    width: auto;
}

.spotlight-reel span {
    color: #fff;
    font-family: 'Alta';
    font-weight: 600;
    font-size: 32px;
    z-index: 1;
}

.spotlight-reel img {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 40px;
    height: auto;
    z-index: 0;
}


.spotlight-reel:hover {
    transform: scale(1.1);
    z-index: 1;
}

.spotlight-reel .view {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 0.75em;
    font-family: 'JOST';
    font-weight: normal;
    text-transform: none;
}

.spotlight-reel .intro {
    align-items: end;
    padding-bottom: 10px;
    font-size: 1.125em;
    font-weight: normal;
    font-family: 'JOST';
    line-height: 1em;
    min-height: 40px;
    text-transform: uppercase;
}

.spotlight-reel .title {
    font-size: 2.625em;
    line-height: 1em;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 35px;
}

.spotlight-reel .category {
    align-items: start;
    padding-top: 10px;
    font-size: 1em;
    font-weight: normal;
    font-family: 'JOST';
    line-height: 1em;
    min-height: 40px;
    text-transform: uppercase;
    line-height: 1.125em;
    padding: 0px 30px;
}

@media (max-width:768px) {
    .spotlight-reel-section {
        justify-content: center;
        flex-wrap: wrap;
    }

    .spotlight-reel {
        width: 45%;
    }

    .home-resources .spotlight-reel {
        width: auto;
    }
}

.page-spotlight .spotlight-reel {
    width: 100%;
    min-height: 250px;
}

.common-modal-dialog .MuiPaper-root {
    position: relative;
}

.common-modal-dialog .slide-prev,
.common-modal-dialog .slide-next {
    position: absolute;
    top: 88px;
    padding: 0px;
    margin: 0px;
    background-color: #000;
    color: #fff;
    min-width: 25px !important;
    height: 103px;
    display: grid;
    align-content: center;
    outline: none;
    font-size: 36px;
}

.common-modal-dialog .slide-prev:hover,
.common-modal-dialog .slide-next:hover {
    background-color: #d3a11f;
}

.common-modal-dialog .slide-prev {
    left: 10px;
}

.common-modal-dialog .slide-next {
    right: 10px;
}

.common-modal-dialog .meta.row {
    align-items: start !important;
}

.spotlight-reel video {
    width: 100% !important;
    height: 100% !important;
}

.reactEasyCrop_CropArea {
    border-radius: 100%;
}

@media (max-width: 786px) {

    .job-metas .category-job,
    .job-metas .job-location,
    .job-metas .job-deadline {
        width: 100%;
    }

    .jobs-list-container .job-location.location,
    .jobs-list-container .job-location.location a,
    .jobs-list-container .job-location.location svg {
        font-size: 16px;
    }

    .job-metas .job-deadline {
        margin-left: 20px;
    }
}

#jobapply-form #remember,
#jobapply-form label[for="remember"] {
    cursor: pointer;
}

@media (min-width:1024px) {
    .about-page .about-text {
        margin: 0px 100px;
    }
}

.search-box-common.search-box-black-gold .btn {
    background-color: #000;
    color: #fff;
    border: 2px solid #000;
}

.search-box-common.search-box-black-gold .btn:hover {
    background-color: #000;
    color: #d3a11f;
    border: 2px solid #000;
}

.agency-page-myjobs.tabular .job-table-status .badge.bg-primary {
    background-color: #0dcaf0 !important;
}

@media (max-width: 540px) {
    .agency-page-myjobs.tabular.dialog.creatives-search .profile-header .container {
        padding: 0px 20px;
    }

    .agency-page-myjobs.tabular.dialog.creatives-search .profile-header .container .row .header {
        flex-direction: column;
    }

    .agency-page-myjobs.tabular.dialog.creatives-search .job-item .d-flex .inner-left {
        flex-direction: column;
    }

    .agency-page-myjobs.tabular.dialog.creatives-search .job-item .d-flex .inner-left>div {
        width: max-content;
    }

    .agency-page-myjobs.tabular.dialog.creatives-search .job-item .d-flex .inner-left .image-loader {
        justify-content: start;
    }
}

.common-modal-dialog.creatives-search .common-modal-actions-apply-now {
    position: relative;
    z-index: 1;
}

.common-modal-dialog.creatives-search .MuiDialogContent-root {
    position: relative;
    z-index: 0;
}

.post-group-info {
    color: rgb(125, 129, 132);
    font-size: 10pt;
    font-weight: 400;
}