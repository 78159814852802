.notif-list {
    .notif-item {
        background: white;
        transition: all 0.25s ease 0s;
        gap: 10px;
        flex-direction: row;
        width: 100%;
        height: unset;
        align-items: center;
        overflow: hidden;
        position: relative;
        padding: 24px 32px;
        background-color: rgb(255, 255, 255);
        display: flex;
        margin-bottom: 10px;
        border-radius: 5px;
    }

    .notif-item-dashboard {
       border-bottom: 1px solid;
       padding: 10px 0;
    }

    .notif-details {
        flex: 1;
    }

    .username {
        font-weight: 500;
    }

    .notif-time {
        font-size: 12px;
        color: #858585;
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .notif-content {
        margin-top: 10px;
        font-size: 15px;
        display: block;
    }

    .user-avatar {
        align-self: flex-start;
    }

    .notif-actions {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .notif-actions {
        button {
            background: #b9b7b7;
            color: white;
            font-size: 20px;
        }

        button:hover {
            background: black;
        }
    }

}