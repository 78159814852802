.page-spotlight .spotlight-slider:hover .spotlight-image {
    -webkit-transform: scale(1.07);
    -ms-transform: scale(1.07);
    -o-transform: scale(1.07);
    transform: scale(1.07);
}

.spotlight-image {
    -webkit-transition: all 0.6s ease-in-out 0s;
    -o-transition: all 0.6s ease-in-out 0s;
    transition: all 0.6s ease-in-out 0s;
}